<template>
  <div>
    <el-card>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            path: '/talent/policy/backstage?page=' + this.$route.query.page,
          }"
          v-if="this.$route.query.title === '人才政策'"
        >
          人才政策
        </el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ path: '/notice?page=' + this.$route.query.page }"
          v-else
          >通知公告
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="this.$route.query.title === '人才政策'"
          >编辑人才政策</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>编辑通知公告</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form ref="form" :model="form" label-width="80px" class="el-form">
        <el-form-item label="标题">
          <el-input v-model="form.title" placement="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <editor ref="editor" />
        </el-form-item>
      </el-form>

      <el-row>
        <el-col :span="3" :offset="9">
          <el-button type="primary" class="text" @click="requestEdit"
            >确定</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="info" class="text" @click="requestCancel"
            >取消</el-button
          >
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import editor from "../../../components/editor";
import configDate from "@/utils/config";

export default {
  data() {
    return {
      host: configDate.url,
      form: {
        columnId: "",
        title: "",
        content: "",
        id: "",
        fileIds: [],
      },
      importHeaders: { token: sessionStorage.getItem("Token") }, // 上传的请求头部
      id: "",
      fileList: [],
    };
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    getData() {
      console.log(this.$route.query.title);
      let self = this;
      this.$axios
        .get("/column/page")
        .then((response) => {
          if (response.data.status === 200) {
            response.data.data.records.forEach((Array) => {
              if (this.$route.query.title === "人才政策") {
                if (Array.name === "人才政策") {
                  self.form.columnId = Array.id;
                  this.$axios
                    .get("/article?id=" + this.$route.query.id)
                    .then((response) => {
                      console.log(response);
                      self.form.title = response.data.data.title;
                      self.$refs.editor.myValue =
                        response.data.data.clobDO.content;
                      self.id = response.data.data.id;
                      response.data.data.files.forEach((item) => {
                        self.form.fileIds.push(item.id);
                        self.fileList.push({
                          id: item.id,
                          name: item.name,
                          url: configDate.url + item.path,
                        });
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              } else {
                if (Array.name === "通知公告") {
                  self.form.columnId = Array.id;
                  this.$axios
                    .get("/article?id=" + this.$route.query.id)
                    .then((response) => {
                      console.log(response);
                      self.id = response.data.data.id;
                      self.form.title = response.data.data.title;
                      self.$refs.editor.myValue =
                        response.data.data.clobDO.content;
                      response.data.data.files.forEach((item) => {
                        self.form.fileIds.push(item.id);
                        self.fileList.push({
                          id: item.id,
                          name: item.name,
                          url: configDate.url + item.path,
                        });
                      });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }
              }
            });
          } else {
            self.$root.warn("数据加载失败" + response.data.status);
          }
          self.total = response.data.data.total;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 文件上传成功
    uploadSuccessFile(response) {
      const self = this;
      if (response.status === 200) {
        self.form.fileIds.push(response.data.id);
      } else {
        self.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    // 控制上传文件格式及大小
    uploadBeforeFile(file) {
      const isJPG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error("图片只能是 png 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },

    //删除文件
    handleRemove(file) {
      let self = this;
      self.$axios.delete("/attach?id=" + file.id).then(function(res) {
        if (res.data.status === 200) {
          self.$notify({
            title: "删除文件成功",
            type: "warning",
          });
        } else {
          self.$notify({
            title: "删除文件失败",
            type: "warning",
          });
        }
      });
    },

    // 删除文件前
    beforeRemove(file) {
      let self = this;
      let id;
      if (file.id) {
        id = file.id;
      }
      if (file.response) {
        id = file.response.data.id;
      }
      if (!id) return;
      this.$axios.delete("/file/delete?id=" + id).then((response) => {
        if (response.data.status === 200) {
          let arr = self.form.fileIds.indexOf(id);
          self.form.fileIds.splice(arr, 1);
        } else {
          self.$notify({
            title: "删除文件失败",
            type: "warning",
          });
        }
      });
    },

    //  点击查看文件
    handlePreview(file) {
      this.seePhoto = true;
      this.addressId = configDate.fileurl + file.path;
    },

    requestEdit() {
      let self = this;
      self.form.content = self.$refs.editor.myValue;
      if (self.form.title.length <= 0) {
        self.$root.warn("请输入标题", "");
        return;
      } else if (self.form.content.length <= 0) {
        self.$root.warn("请输入内容", "");
        return;
      }
      this.$axios
        .put("/article", self.form)
        .then(function(response) {
          console.log(response);
          if (response.data.status === 200) {
            self.$root.success("编辑成功");
            if (this.$route.query.title === "人才政策") {
              self.$router.push({
                path: "/talent/policy/backstage?page=" + this.$route.query.page,
              });
            } else {
              self.$router.push({
                path: "/notice?page=" + this.$route.query.page,
              });
            }
          } else {
            self.$root.warn("编辑失败");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    handleChange(response) {
      if (response.status === 200) {
        this.form.fileIds.push(response.data.id);
      }
    },

    requestCancel() {
      this.$router.back();
    },
  },
  components: {
    editor,
  },
  created() {
    this.getData();
    this.form.id = this.$route.query.id;
  },
};
</script>

<style scoped lang="less">
.el-form {
  margin: 32px 32px 0;
}

.text {
  color: #ffffff;
}

.file-card-height {
  margin: 0 8px;
  min-width: 200px;
  width: 100%;
  max-width: 400px;

  .file-card {
    width: 100%;
    vertical-align: top;
  }

  .upload-demo {
    width: 100%;
    border: 1px solid #0c94ea;
    border-radius: 8px;
    padding: 8px;

    .file-button {
      color: #0c94ea;
      display: inline-block;
      margin-left: 24px;
    }

    .file-name {
      display: inline-block;
      margin-left: 24px;
    }
  }
}
</style>
